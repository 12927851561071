import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { FileDropModule } from 'ngx-file-drop';
import { MomentModule } from 'ngx-moment';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule } from 'primeng/primeng';
import { ButtonComponent } from './components/button/button.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { GeoPickerComponent } from './components/geo-picker/geo-picker.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FindPipe } from './pipes/find.pipe';
import { LeftpadPipe } from './pipes/leftpad.pipe';
import { LetterPipe } from './pipes/letter.pipe';
import { NumberToTimePipe } from './pipes/number-to-time.pipe';

@NgModule({
  declarations: [
    FileDropComponent,
    ButtonComponent,
    NavigationComponent,
    ConfirmModalComponent,
    LeftpadPipe,
    LetterPipe,
    GeoPickerComponent,
    NumberToTimePipe,
    FindPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatExpansionModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    FileDropModule,
    MomentModule,
    CalendarModule,
    TranslateModule,
    ToastrModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatExpansionModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    LeftpadPipe,
    LetterPipe,
    FileDropComponent,
    ButtonComponent,
    NavigationComponent,
    MomentModule,
    CalendarModule,
    GeoPickerComponent,
    NumberToTimePipe,
    FindPipe,
    TranslateModule,
    ToastrModule
  ]
})
export class SharedModule {
}
