import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { StateService } from '../../../../core/state/state.service';
import { Customer } from '../../../../shared/models/customer';
import { GeoData } from '../../../../shared/models/geo-data';
import { Timewindow } from '../../../../shared/models/time-window';
import { timeValidator } from '../../../../shared/validator/time-validator';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: [ './customer-detail.component.scss' ]
})
export class CustomerDetailComponent implements OnInit, OnDestroy {

  public destroy$: Subject<any> = new Subject();

  public readonly form: FormGroup = this.fb.group(this.convertCustomerToForm(new Customer()));

  private _customer: Customer;

  @Input()
  public set customer(customer: Customer) {
    this._customer = customer;
    this.form.setControl('timewindows', this.createTimeWindowFormGroup(customer.timewindows));
    this.form.patchValue(customer);
  }

  @Output() public readonly update: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Output() public readonly delete: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Output() public readonly add: EventEmitter<any> = new EventEmitter();

  get timewindows(): FormArray {
    return this.form.get('timewindows') as FormArray;
  }

  get globalParams(){
    return this.state.globalParams;
  }

  constructor(private toastrService:ToastrService, private fb: FormBuilder, private state:StateService) {
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map(formValues => this.mergeValues(this._customer, this.sanitizeFormValues(formValues)))
      )
      .subscribe(customer => this.update.emit(customer));

  }

  mergeValues(customer: Customer, changes: Object): Customer {
    Object.keys(changes)
      .forEach(key => customer[ key ] = changes[ key ]);

    return customer;
  }

  convertCustomerToForm(customer: Customer = new Customer()): any {
    return {
      ...customer,
      timewindows: this.createTimeWindowFormGroup(customer.timewindows),
      servicetime: [ customer.servicetime || this.globalParams.serviceTime, [ Validators.min(0), Validators.required ] ],
      demand: [ customer.demand || this.globalParams.demand, [ Validators.min(0), Validators.required ] ]
    };
  }

  createTimeWindowFormGroup(timewindows: Array<Timewindow> = [ new Timewindow() ]): FormArray {
    return this.fb.array(
      timewindows.map(timewindow => this.createNewTimeWindowForm(timewindow))
    );
  }

  createNewTimeWindowForm(timewindow: Timewindow = new Timewindow('0:00', '23:59')): FormGroup {
    const group = this.fb.group({
      min: [ timewindow.min, Validators.required ],
      max: [ timewindow.max, Validators.required ]
    }, {validator: [timeValidator]});
    group.statusChanges.pipe(filter(value => value === 'INVALID'))
      .subscribe(() => {
      const splitMin = convertMomentToString(group.value.min)
        .split(':');
      const splitMax = convertMomentToString(group.value.max)
        .split(':');
      if (splitMin[0] === splitMax[0] && splitMin[1] > splitMax[1] || splitMin[0] > splitMax[0]) {
        this.toastrService.error(
          convertMomentToString(group.value.min) + ' - ' + convertMomentToString(group.value.max),
          'Timewindow wrong');
      }
    }
    );
    return group;

    function convertMomentToString(value: any, format: string = 'HH:mm'): string {
      return moment(value, format).format(format);
    }
  }

  pushTimeWindowToForm(): void {
    const formArray: FormArray = this.form.get('timewindows') as FormArray;

    formArray.push(this.createNewTimeWindowForm());
  }

  removeTimeWindowFromForm(index: number): void {
    const formArray: FormArray = this.form.get('timewindows') as FormArray;

    formArray.removeAt(index);
  }

  setGeoData(geoData: GeoData): void {
    this.form.patchValue(geoData);
  }

  deleteCustomer(): void {
    this.delete.emit(this._customer);
  }

  addCustomer(): void {
    this.add.emit();
  }

  sanitizeFormValues(customer: Customer): Customer {
    const convertDateToString = val => val instanceof Date
      ? moment(val)
        .format('LT')
      : val;

    const sanitizeTimeWindow = timewindow => {
      return {
        min: convertDateToString(timewindow.min),
        max: convertDateToString(timewindow.max)
      } as Timewindow;
    };

    return {
      ...customer,
      timewindows: customer.timewindows.map(sanitizeTimeWindow)
    } as Customer;
  }

  isDepot(): boolean {
    return this._customer.name === 'depot';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
