import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StateService } from '../../../core/state/state.service';
import { Vehicle } from '../../../shared/models/vehicle';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: [ './vehicle.component.scss' ]
})
export class VehicleComponent implements OnInit, OnDestroy {

  private destroy$: Subject<any> = new Subject();

  public showOverview = true;
  public selectedIndex = undefined;

  public vehicles: Array<Vehicle>;

  constructor(private state: StateService) {
  }

  ngOnInit(): void {
    this.state.vehicles$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(vehicles => this.vehicles = vehicles);
  }

  addVehicle(): void {
    this.vehicles.push(new Vehicle());
    this.state.vehicles = this.vehicles;

    this.setSelectedIndex(this.vehicles.length - 1);
  }

  deleteVehicle(vehicleToDelete: Vehicle): void {
    this.vehicles = this.vehicles.filter(vehicle => JSON.stringify(vehicle) !== JSON.stringify(vehicleToDelete));
    this.state.vehicles = this.vehicles;

    this.setSelectedIndex();
  }

  updateVehicle(updatedVehicle: Vehicle, i: number): void {
    this.vehicles[ i ] = updatedVehicle;

    this.state.vehicles = this.vehicles;
  }

  setSelectedIndex(index?: number): void {
    this.selectedIndex = index;

    this.setShowOverview(isNaN(index));
  }

  setShowOverview(bool: boolean): void {
    this.showOverview = bool;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
