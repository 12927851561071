import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '../../../core/state/state.service';
import { NavigationItem } from './navigation-item';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public selected$ = this.state.sidenavSelectedTab$;

  @Input() items: Array<NavigationItem>;

  @Input() showIcon: boolean;
  @Input() showLabel: boolean;

  @Input() direction: 'vertical' | 'horizontal' = 'horizontal';

  constructor(private state: StateService) {
  }

  ngOnInit(): void {
  }

  setSelected(i: number): void {
    this.state.resetMapState(i === 0);
    this.state.sidenavSelectedTab = i;
  }

}
