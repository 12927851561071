import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: Error): void {
    console.warn(error);
    try {
      this.toastrService.error(error.message, error.name !== 'Error' ? error.name : undefined);
    } catch (e) {
      console.error('error handling caused error');
      console.error(e);
    }
  }
}
