import { Injectable } from '@angular/core';
import { LngLatBoundsLike } from 'mapbox-gl';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Customer } from '../../shared/models/customer';
import { Depot } from '../../shared/models/depot';
import { EditorData } from '../../shared/models/editor-data';
import { GlobalParams } from '../../shared/models/global-params';
import { Marker } from '../../shared/models/marker';
import { Stop } from '../../shared/models/stop';
import { Vehicle } from '../../shared/models/vehicle';
import { VehicleTour } from '../../shared/models/vehicle-tour';
import { DATA } from './data';

export interface RouteState {
  [key: number]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public readonly sidenavSelectedTab$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly vehicleTours$: BehaviorSubject<Array<VehicleTour>> = new BehaviorSubject<Array<VehicleTour>>([]);
  public readonly mapBounds$: Subject<LngLatBoundsLike> = new Subject<LngLatBoundsLike>();

  public readonly vehicles$: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject<Array<Vehicle>>([]);
  public readonly customers$: BehaviorSubject<Array<Customer>> = new BehaviorSubject<Array<Customer>>([]);
  public readonly depot$: BehaviorSubject<Depot> = new BehaviorSubject<Depot>(new Depot());
  public readonly globalParams$: BehaviorSubject<GlobalParams> = new BehaviorSubject<GlobalParams>(new GlobalParams());
  public readonly dynamicMarker$: BehaviorSubject<Marker> = new BehaviorSubject<Marker>(undefined);

  public readonly hiddenRoutes$: BehaviorSubject<RouteState> = new BehaviorSubject<RouteState>({});
  public readonly highlightedRoute$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);


  public get sidenavSelectedTab(): number {
    return this.sidenavSelectedTab$.getValue();
  }

  public set sidenavSelectedTab(index: number) {
    this.sidenavSelectedTab$.next(index);
  }

  public set mapBounds(mapBounds: LngLatBoundsLike) {
    this.mapBounds$.next(mapBounds);
  }

  public get vehicleTours(): Array<VehicleTour> {
    return this.vehicleTours$.getValue();
  }

  public set vehicleTours(vehicleTours: Array<VehicleTour>) {
    // mapbox issue https://github.com/Wykks/ngx-mapbox-gl/issues/88
    // until thats fixed we need to reset and redraw in next tick
    this.vehicleTours$.next([]);

    setTimeout(() => {
      this.vehicleTours$.next(vehicleTours);

      if (vehicleTours.length > 0) {
        const flatMap = (acc, curr) => acc.concat(curr);

        const tourStops = vehicleTours
          .map(tour => tour.stops)
          .reduce(flatMap, []);

        this.mapBounds = this.calculateMapBounds(tourStops);

        const inf = vehicleTours.findIndex(tour => tour.name==='inf');
        if(0 <= inf){
          this.setHiddenRoute(inf, true);
        }
      }
    });
  }

  public get vehicles(): Array<Vehicle> {
    return this.vehicles$.getValue();
  }

  public set vehicles(vehicles: Array<Vehicle>) {
    this.vehicles$.next(vehicles);
  }

  public get customers(): Array<Customer> {
    return this.customers$.getValue();
  }

  public set customers(customers: Array<Customer>) {
    this.customers$.next(customers);

    this.mapBounds = this.calculateMapBounds(customers.concat(this.depot));
  }

  public get globalParams(): GlobalParams {
    return this.globalParams$.getValue();
  }

  public set globalParams(globalParams: GlobalParams) {
    this.globalParams$.next(globalParams);
  }

  public get depot(): Depot {
    return this.depot$.getValue() || new Depot();
  }

  public set depot(depot: Depot) {
    this.depot$.next(depot);

    this.mapBounds = this.calculateMapBounds(this.customers.concat(depot));
  }

  public get dynamicMarker(): Marker {
    return this.dynamicMarker$.getValue();
  }

  public set dynamicMarker(dynamicMarker: Marker) {
    this.dynamicMarker$.next(dynamicMarker);
  }

  public get hiddenRoutes(): RouteState {
    return this.hiddenRoutes$.getValue();
  }

  public set hiddenRoutes(hiddenRoutes: RouteState) {
    this.hiddenRoutes$.next(hiddenRoutes);
  }

  public get highlightedRoute(): number {
    return this.highlightedRoute$.getValue();
  }

  public set highlightedRoute(highlightedRoute: number) {
    this.highlightedRoute$.next(highlightedRoute);
  }

  constructor() {
    this.setEditorData();
  }

  public resetVehicleTours(): void {
    this.vehicleTours$.next([]);
  }

  public getDestinations(): Observable<Array<Customer>> {
    return combineLatest(this.depot$, this.customers$)
      .pipe(
        map(([ depot, customers ]) => [ depot, ...customers ]),
        shareReplay(1)
      );
  }

  public setEditorData(editorData: any = DATA): void {

    this.vehicleTours = [];

    const isDepot = (customer: Customer): boolean => customer.name === 'depot';

    this.globalParams = editorData.globalParams as GlobalParams;

    this.vehicles = editorData.vehicleTypes.map(vehicle => vehicle as Vehicle);

    this.depot = editorData.destinations.find(isDepot);

    this.customers = editorData.destinations
      .filter(destination => !isDepot(destination));
  }

  public getEditorData(): Observable<EditorData> {
    return combineLatest(this.globalParams$, this.vehicles$, this.getDestinations())
      .pipe(
        tap(() => this.resetVehicleTours()),
        map(([ globalParams, vehicles, destinations ]) =>
          new EditorData(this.globalParamsWithoutServiceTimeAndDemand(), vehicles, destinations)),
        shareReplay(1)
      );
  }

  public globalParamsWithoutServiceTimeAndDemand(): GlobalParams {
    const copyGlobalParams = Object.assign({}, this.globalParams);

    delete copyGlobalParams.serviceTime;
    delete copyGlobalParams.demand;

    return copyGlobalParams;
  }

  public convertToRequestData(): string {
    return JSON.stringify({
      globalParams: this.globalParamsWithoutServiceTimeAndDemand(),
      vehicleTypes: this.vehicles,
      destinations: [ this.depot, ...this.customers]
    });
  }

  setHiddenRoute(i: number, hidden: boolean): void {
    this.hiddenRoutes[ i ] = hidden;
  }

  setHighlightedRoute(i: number): void {
    this.highlightedRoute = i;
  }

  calculateMapBounds(destinations: Array<Stop | Customer>): LngLatBoundsLike {
    const initialValues = { minLng: 999999, minLat: 999999, maxLng: -999999, maxLat: -999999 };

    const reduceBounds = (acc, curr) => {
      return {
        minLng: Math.min(acc.minLng, curr.longitude),
        minLat: Math.min(acc.minLat, curr.latitude),
        maxLng: Math.max(acc.maxLng, curr.longitude),
        maxLat: Math.max(acc.maxLat, curr.latitude)
      };
    };

    const bounds = destinations
      .filter(destination => !isNaN(destination.longitude) && !isNaN(destination.latitude))
      .reduce(reduceBounds, { ...initialValues });

    return [
      [ bounds.maxLng, bounds.minLat ],
      [ bounds.minLng, bounds.maxLat ]
    ];
  }

  resetMapState(clearHiddenRoutes: boolean = true): void {
    this.dynamicMarker = undefined;
    if(clearHiddenRoutes) this.hiddenRoutes = {};
  }

}
