import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToTime'
})
export class NumberToTimePipe implements PipeTransform {

  transform(num: number): any {
    const hours: number = Math.floor(num / 60);
    const minutes: number = Math.floor(num % 60);

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

  }

}
