import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs/operators';
import { HttpBaseService } from '../../core/async/http/http-base.service';
import { StateService } from '../../core/state/state.service';
import { ActionBarState } from '../../shared/enums/action-bar-state.enum';
import { VehicleTour } from '../../shared/models/vehicle-tour';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, OnDestroy {

  public readonly ActionBarState = ActionBarState;

  @Output() public readonly setActionBarState: EventEmitter<ActionBarState> = new EventEmitter<ActionBarState>();
  file: File;

  constructor(private toastrService: ToastrService, private http: HttpBaseService, private state: StateService) {
  }

  ngOnInit(): void {
  }

  calculate(): void {
    this.clearDynamicMarker();

    const api = '/beelivery-rest/rest/upload/json';
    const body: string = this.state.convertToRequestData();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post(api, body, httpOptions)
      .pipe(
        map(res => res.vehicles.map(vehicle => vehicle as VehicleTour)),
        tap(() => this.state.sidenavSelectedTab = 1)
      )
      .subscribe(
        vehicleTours => this.state.vehicleTours = vehicleTours,
        error => this.toastrService.error(error.message, `${error.name} (${error.statusText})`)
      );

  }

  resetEditor(): void {
    this.state.setEditorData();
    this.state.resetVehicleTours();
    this.file = null;
  }

  clearDynamicMarker(): void {
    this.state.resetMapState();
  }

  downloadJSON(downloadButton: any): void {
    this.state.getEditorData()
      .subscribe(data => {
          downloadButton.href = `data:text/plain;charset=utf-u,${encodeURIComponent(JSON.stringify(data))}`;
          downloadButton.download = `data.json`;
        }
      );
  }

  ngOnDestroy(): void {
  }

}
