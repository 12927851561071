import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpBaseService } from '../core/async/http/http-base.service';
import { Marker } from '../shared/models/marker';

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {

  private readonly api = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

  constructor(private http: HttpBaseService) {
  }

  reverseGeocode(longitude: number, latitude: number): Observable<Marker> {
    const url = `${this.api}/${longitude},${latitude}.json`;

    return this.http.get(url, this.getHttpOptions())
      .pipe(
        map(featureCollection => this.convertFeatureCollectionToMarker(featureCollection))
      );
  }

  forwardGeocoding(address: string): Observable<Marker> {
    const url = `${this.api}/${address}.json`;

    return this.http.get(url, this.getHttpOptions())
      .pipe(
        map(featureCollection => this.convertFeatureCollectionToMarker(featureCollection)),
      );
  }

  convertFeatureCollectionToMarker(featureCollection: any): Marker {
    const bestMatch = this.findFeaturePlaceType(featureCollection);

    if (!bestMatch) {
      return undefined;
    }

    return {
      address: bestMatch.place_name_de,
      longitude: bestMatch.geometry.coordinates[0],
      latitude: bestMatch.geometry.coordinates[1]
    };
  }

  getHttpOptions(): any {
    return {
      params: new HttpParams()
        .set('access_token', environment.mapBoxAccessToken)
        .set('language', 'de')
    };
  }

  findFeaturePlaceType(featureCollection: any): any {
    const findFeaturePlaceType = (collection, type) => collection.features.find(feature => feature.place_type.includes(type));

    return findFeaturePlaceType(featureCollection, 'address')
      || findFeaturePlaceType(featureCollection, 'poi')
      || findFeaturePlaceType(featureCollection, 'postcode')
      || findFeaturePlaceType(featureCollection, 'place');
  }
}
