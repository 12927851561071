import { NgModule } from '@angular/core';
import { AsyncModule } from './async/async.module';
import { StateService } from './state/state.service';

@NgModule({
  imports: [
    AsyncModule
  ],
  declarations: [],
  providers: [
    StateService,
  ]
})
export class CoreModule { }
