import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { StateService } from '../../../core/state/state.service';
import { CoordinatePickerService } from '../../../map/coordinate-picker.service';
import { GeocodeService } from '../../../map/geocode.service';
import { GeoData } from '../../models/geo-data';

@Component({
  selector: 'app-geo-picker',
  templateUrl: './geo-picker.component.html',
  styleUrls: ['./geo-picker.component.scss']
})
export class GeoPickerComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('address') address: ElementRef;

  readonly form: FormGroup = this.fb.group(new GeoData());

  private readonly destroy$: Subject<any> = new Subject();
  private _geoData: GeoData;
  lookupSuccess: 'success' | 'not-found';

  @Input() label: string;

  @Input() set value(geoData: GeoData) {
    this._geoData = geoData;

    !geoData.address && geoData.longitude && geoData.latitude
      ? this.getAddress(geoData)
        .subscribe(
          res => this.form.patchValue(res),
          error => this.toastrService.error(error.message, `${error.name} (${error.statusText})`)
        )
      : this.form.patchValue(geoData);
  }

  @Output() readonly setValue: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private geocode: GeocodeService,
    private state: StateService,
    private coordinatePickerService: CoordinatePickerService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(formValue => JSON.stringify(formValue) !== JSON.stringify(this._geoData)),
        map(formValue => !formValue.address && this._geoData.address ? new GeoData() : formValue)
      )
      .subscribe(val => this.setValue.emit(val));

    fromEvent(this.address.nativeElement, 'keydown')
      .pipe(

        takeUntil(this.destroy$),
        debounceTime(500),
        filter((event: KeyboardEvent) => 0 <= ['Enter', 'Tab'].indexOf(event.key)),
        switchMap(() => this.geocode.forwardGeocoding(this.form.value.address)),
        tap(res => this.state.dynamicMarker = res),
        tap(res => this.lookupSuccess = res ? 'success' : 'not-found'),
        filter(geoData => !!geoData)
      )
      .subscribe(
        geoData => this.form.patchValue(geoData),
        error => this.toastrService.error(error.message, error.name)
      );

    fromEvent(this.address.nativeElement, 'blur')
      .pipe(
        takeUntil(this.destroy$),
        filter(() =>  this.lookupSuccess !== 'not-found')
      )
      .subscribe(() => this.lookupSuccess = undefined);
  }

  getAddress(geoData: GeoData): Observable<any> {
    return this.geocode.reverseGeocode(geoData.longitude, geoData.latitude)
      .pipe(
        tap(res => this.state.dynamicMarker = res)
      );
  }

  getCoordinates(): void {
    this.coordinatePickerService.getCoordinates()
      .pipe(
        take(1),
        switchMap(res => this.getAddress(res))
      )
      .subscribe(
        geoData => this.form.patchValue(geoData),
        error => this.toastrService.error(error.message, `${error.name} (${error.statusText})`)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
