import { ErrorHandler, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { ErrorsHandler } from '../core/errors-handler.service';
import { SharedModule } from '../shared/shared.module';
import { ActionBarComponent } from './action-bar.component';
import { JobListComponent } from './job-list/job-list.component';
import { MenuComponent } from './menu/menu.component';
import { CustomerDetailComponent } from './planning/customer/customer-detail/customer-detail.component';
import { CustomerListComponent } from './planning/customer/customer-list/customer-list.component';
import { CustomerComponent } from './planning/customer/customer.component';
import { EditorComponent } from './planning/editor/editor.component';
import { PlanningComponent } from './planning/planning.component';
import { VehicleDetailComponent } from './planning/vehicle/vehicle-detail/vehicle-detail.component';
import { VehicleListComponent } from './planning/vehicle/vehicle-list/vehicle-list.component';
import { VehicleComponent } from './planning/vehicle/vehicle.component';
import { SummaryComponent } from './summary/summary.component';

@NgModule({
  declarations: [
    ActionBarComponent,
    PlanningComponent,
    SummaryComponent,
    EditorComponent,
    VehicleComponent,
    VehicleListComponent,
    VehicleDetailComponent,
    CustomerComponent,
    CustomerListComponent,
    CustomerDetailComponent,
    MenuComponent,
    JobListComponent
  ],
  imports: [
    SharedModule, ToastrModule
  ],
  exports: [
    ActionBarComponent,
    MenuComponent
  ],
  providers: [{provide: ErrorHandler, useClass: ErrorsHandler}]
})
export class ActionBarModule {
}
