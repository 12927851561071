import { VehicleType } from '../enums/vehicle-type.enum';
import { Timewindow } from './time-window';

export class Vehicle {
  constructor(
    public name: string = '',
    public quantity: number = 0,
    public capacity: number = 0,
    public type: VehicleType = VehicleType.None,
    public isEnabled: boolean = true,
    public timewindows: Array<Timewindow> = [new Timewindow()],
    public maxDuration: number = 1440
  ) {
  }
}
