import { Time } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Timewindow } from 'src/app/shared/models/time-window';
import { StateService } from '../../../core/state/state.service';
import { Customer } from '../../../shared/models/customer';
import { TranslateService } from '@ngx-translate/core';
import { Depot } from 'src/app/shared/models/depot';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: [ './customer.component.scss' ]
})
export class CustomerComponent implements OnInit, OnDestroy {

  private destroy$: Subject<any> = new Subject();

  public showOverview = true;
  public selectedIndex = undefined;

  public customers: Array<Customer>;

  // Import
  private reader: FileReader = new FileReader();
  private file: File;
  importData: string;
  public overrideCustomers: boolean;

  constructor(private readonly state: StateService, private readonly toastrService: ToastrService, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.state.getDestinations()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(customers => this.customers = customers);

    this.reader.onload = () => {
      try {
        this.convertCsvToCustomers(this.reader.result as string);
      } catch (error) {
        this.toastrService.error('CSV is not valid.');
        this.file = undefined;

        return;
      }
    };
  }

  addCustomer(): void {
    this.customers.push(Object.assign(new Customer(),
                        {servicetime: this.state.globalParams.serviceTime, demand: this.state.globalParams.demand}));
    this.state.customers = this.customers.filter(customer => customer.name !== 'depot');

    this.setSelectedIndex(this.customers.length - 1);
  }

  deleteCustomer(customerToDelete: Customer): void {
    this.customers = this.customers.filter(customer => JSON.stringify(customer) !== JSON.stringify(customerToDelete));
    this.state.customers = this.customers.filter(customer => customer.name !== 'depot');

    this.setSelectedIndex();
  }

  updateCustomer(updatedCustomer: Customer, i: number): void {

    if (updatedCustomer.name === 'depot') {
      this.state.depot = updatedCustomer;
    } else {
      this.customers[ i ] = updatedCustomer;
      this.state.customers = this.customers.filter(customer => customer.name !== 'depot');
    }

  }

  setSelectedIndex(index?: number): void {
    this.selectedIndex = index;

    this.setShowOverview(isNaN(index));
  }

  setShowOverview(bool: boolean): void {
    this.showOverview = bool;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public processFile(file: File): void {
    this.reader.readAsText(file);
    this.file = file;
  }

  public uploadCsvFile(e: any): void {
    this.processFile(e.files[0]);
  }

  private getTimeWindowFromString(input: string): Array<Timewindow> {
    const timewindows: Array<Timewindow> = [];
    const splitStrings: Array<string> = input.split('|');

    for (const timewindowString of splitStrings) {
      const minMaxSplit = timewindowString.split('-');
      timewindows.push(new Timewindow(minMaxSplit[0], minMaxSplit[1]));
    }

    return timewindows;
  }

  private convertCsvToCustomers(input: string): void {
    this.importData = input;
    let lines: Array<string> = input.split(/\n/);
    lines.shift();
    lines = lines.filter(line => line);

    const convertedCustomers: Array<Customer> = [];

    let isDepotSet = false;
    for (const line of lines) {
      const fields: Array<string> = line.split(';');
      if (fields[0] === 'depot') {
        if (!isDepotSet) {
          this.state.depot = new Depot(+fields[5], +fields[6], fields[1], this.getTimeWindowFromString(fields[2]));
          isDepotSet = true;
        }
      } else {
        convertedCustomers.push(new Customer(fields[0], +fields[5], +fields[6], fields[1],
                                this.getTimeWindowFromString(fields[2]),
                                fields[3] === '' ? this.state.globalParams.serviceTime : +fields[3],
                                fields[4] === '' ? this.state.globalParams.demand : +fields[4],
                                true));
      }
    }

    if (this.overrideCustomers) {
      this.customers = [];
    }
    // this.customers.push(...getUnique(convertedCustomers, 'name'));
    this.customers.push(...convertedCustomers);
    this.state.customers = this.customers.filter(customer => customer.name !== 'depot');

    this.toastrService.success(this.translate.currentLang === 'de' ? 'CSV Import erfolgreich!' : 'CSV import successful!');
  }

}
