import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vehicle } from '../../../../shared/models/vehicle';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {

  @Input() public vehicles: Array<Vehicle>;

  @Output() public readonly selectedIndex: EventEmitter<number> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleAll(): void {
    const toggleTo: boolean = !this.vehicles[0].isEnabled;
    this.vehicles.forEach((vehicle: Vehicle) => vehicle.isEnabled = toggleTo);
  }

}
