import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Timewindow } from 'src/app/shared/models/time-window';
import { VehicleType } from '../../../../shared/enums/vehicle-type.enum';
import { Vehicle } from '../../../../shared/models/vehicle';
import { timeValidator } from '../../../../shared/validator/time-validator';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: [ './vehicle-detail.component.scss' ]
})
export class VehicleDetailComponent implements OnInit, OnDestroy {

  public readonly VehicleType = VehicleType;

  get timewindows(): FormArray {
    return this.form.get('timewindows') as FormArray;
  }

  public destroy$: Subject<any> = new Subject();

  public readonly vehicleTypes = Object.keys(VehicleType)
    .map(key => {
      return {
        value: VehicleType[ key ],
        viewValue: key
      };
    });

  public readonly form: FormGroup = this.fb.group(this.convertVehicleToForm(new Vehicle()));
  // public readonly maxRouteDuration = this.fb.control(1440, Validators.min(1));

  convertVehicleToForm(vehicle: Vehicle = new Vehicle()): any {
    return {
      ...vehicle,
      capacity: [ 0, Validators.min(0) ],
      quantity: [ 0, Validators.min(0) ],
      timewindows: this.createTimeWindowFormGroup(vehicle.timewindows),
      maxDuration: [ 1440, Validators.min(0) ]
    };
  }

  private _vehicle: Vehicle;

  @Input()
  public set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
    this.form.setControl('timewindows', this.createTimeWindowFormGroup(vehicle.timewindows));
    this.form.setValue(vehicle);
  }

  @Output() public readonly update: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output() public readonly delete: EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output() public readonly add: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder, private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map(formValues => this.mergeValues(this._vehicle, this.sanitizeFormValues(formValues)))
      )
      .subscribe(vehicle => this.update.emit(vehicle));
  }

  mergeValues(vehicle: Vehicle, changes: Object): Vehicle {
    Object.keys(changes)
      .forEach(key => vehicle[ key ] = changes[ key ]);

    return vehicle;
  }

  deleteVehicle(): void {
    this.delete.emit(this._vehicle);
  }

  addVehicle(): void {
    this.add.emit();
  }

  setVehicleType(vehicleType: any): void {
    this.form.patchValue({ type: vehicleType });
  }

  // ----- Time Window Section -----
  createTimeWindowFormGroup(timewindows: Array<Timewindow> = [ new Timewindow() ]): FormArray {
    return this.fb.array(
      timewindows.map(timewindow => this.createNewTimeWindowForm(timewindow))
    );
  }

  createNewTimeWindowForm(timewindow: Timewindow = new Timewindow('0:00', '23:59')): FormGroup {
    const group = this.fb.group({
      min: [ timewindow.min, Validators.required ],
      max: [ timewindow.max, Validators.required ]
    }, {validator: [timeValidator]});
    group.statusChanges.pipe(filter(value => value === 'INVALID'))
    .subscribe(() => {
      const splitMin = convertMomentToString(group.value.min)
        .split(':');
      const splitMax = convertMomentToString(group.value.max)
        .split(':');

      if (splitMin[0] === splitMax[0] && splitMin[1] > splitMax[1] || splitMin[0] > splitMax[0]) {
        this.toastrService.error(
          convertMomentToString(group.value.min) + ' - ' + convertMomentToString(group.value.max),
          'Timewindow wrong');
        }
      }
    );
    return group;

    function convertMomentToString(value: any, format: string = 'HH:mm'): string {
      return moment(value, format)
      .format(format);
    }
  }

  removeTimeWindowFromForm(index: number): void {
    const formArray: FormArray = this.form.get('timewindows') as FormArray;

    formArray.removeAt(index);
  }

  pushTimeWindowToForm(): void {
    const formArray: FormArray = this.form.get('timewindows') as FormArray;

    formArray.push(this.createNewTimeWindowForm());
  }

  sanitizeFormValues(vehicle: Vehicle): Vehicle {
    const convertDateToString = val => val instanceof Date
      ? moment(val)
        .format('LT')
      : val;

    const sanitizeTimeWindow = timewindow => {
      return {
        min: convertDateToString(timewindow.min),
        max: convertDateToString(timewindow.max)
      } as Timewindow;
    };

    return {
      ...vehicle,
      timewindows: vehicle.timewindows.map(sanitizeTimeWindow)
    } as Vehicle;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
