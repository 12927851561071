import { Customer } from './customer';
import { Timewindow } from './time-window';

export class Depot extends Customer {

  constructor(
    public longitude: number = 48.210033,
    public latitude: number = 16.363449,
    public address: string = undefined,
    public timewindows: Array<Timewindow> = [new Timewindow()]
  ) {
    super(
      'depot',
      longitude,
      latitude,
      address,
      timewindows,
      0,
      0,
      true
    );
  }
}
