export const DATA = {
  'globalParams': {
    'maxRouteDuration': 1440
  },
  'vehicleTypes': [
    {
      'name': 'VW Caddy 2018',
      'quantity': 2,
      'capacity': 60,
      'type': 'caddy',
      'isEnabled': true,
      'timewindows': [
        {
          'min': '06:00',
          'max': '20:00'
        }
      ],
      'maxDuration': 1440
    },
    {
      'name': 'Mercedes 208 D',
      'quantity': 1,
      'capacity': 50,
      'type': 'sprinter',
      'isEnabled': true,
      'timewindows': [
        {
          'min': '06:00',
          'max': '20:00'
        }
      ],
      'maxDuration': 1440
    },
    {
      'name': 'Cero One',
      'quantity': 1,
      'capacity': 10,
      'type': 'cargobike',
      'isEnabled': true,
      'timewindows': [
        {
          'min': '06:00',
          'max': '20:00'
        }
      ],
      'maxDuration': 1440
    }
  ],
  'destinations': [
    {
      'name': 'depot',
      'longitude': 16.36076, 'latitude': 48.17685,
      'timewindows': [
        {
          'min': '06:00',
          'max': '20:00'
        }
      ],
      'servicetime': 0,
      'demand': 0,
      'isEnabled': true
    },
    {
      'name': 'customer1',
      'longitude': 16.31608, 'latitude': 48.20613,
      'timewindows': [
        {
          'min': '08:00',
          'max': '09:00'
        },
        {
          'min': '15:00',
          'max': '16:00'
        }
      ],
      'servicetime': 10,
      'demand': 30,
      'isEnabled': true
    },
    {
      'name': 'customer2',
      'longitude': 16.40288, 'latitude': 48.18815,
      'timewindows': [
        {
          'min': '07:30',
          'max': '08:00'
        },
        {
          'min': '15:00',
          'max': '16:00'
        }
      ],
      'servicetime': 10,
      'demand': 30,
      'isEnabled': true
    },
    {
      'name': 'customer3',
      'longitude': 16.4, 'latitude': 48.2,
      'timewindows': [
        {
          'min': '07:30',
          'max': '08:00'
        },
        {
          'min': '15:00',
          'max': '16:00'
        }
      ],
      'servicetime': 10,
      'demand': 10,
      'isEnabled': true
    },
    {
      'name': 'customer4',
      'longitude': 16.343, 'latitude': 48.1798,
      'timewindows': [
        {
          'min': '07:30',
          'max': '08:00'
        },
        {
          'min': '15:00',
          'max': '16:00'
        }
      ],
      'servicetime': 10,
      'demand': 10,
      'isEnabled': true
    },
    {
      'name': 'customer5',
      'longitude': 16.40854, 'latitude': 48.174,
      'timewindows': [
        {
          'min': '07:45',
          'max': '08:10'
        },
        {
          'min': '15:00',
          'max': '16:00'
        }
      ],
      'servicetime': 10,
      'demand': 10,
      'isEnabled': true
    }
  ]
};
