import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { RouteState, StateService } from '../../core/state/state.service';
import { Customer } from '../../shared/models/customer';
import { Timewindow } from '../../shared/models/time-window';
import { VehicleTour } from '../../shared/models/vehicle-tour';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  selectedDestination: Customer = undefined;
  selectedIndex: number = undefined;
  isExtendedMode: boolean = true;

  readonly vehicleTours$: BehaviorSubject<Array<VehicleTour>> = this.state.vehicleTours$;
  readonly destinations$: Observable<Array<Customer>> = this.state.getDestinations();

  readonly hiddenRoutes$: Observable<RouteState> = this.state.hiddenRoutes$;
  readonly highlightedRoute$: Observable<number> = this.state.highlightedRoute$;

  constructor(private readonly state: StateService) {
  }

  ngOnInit(): void {}

  getCustomerAddress(name: string): Observable<string> {
    return this.state.getDestinations()
      .pipe(
        map(customers => customers.find(customer => customer.name === name).address),
        take(1)
      );
  }

  getCustomer(name: string): Observable<Customer> {
    return this.state.getDestinations()
      .pipe(
        map(customers => customers.find(customer => customer.name === name)),
        take(1)
      );
  }

  setSelected(name: string, i: number): void {
    this.destinations$
      .pipe(
        take(1),
        map(destinations => destinations.find(destination => destination.name === name))
      )
      .subscribe(destination => {
        if (this.selectedIndex !== i) {
          this.selectedDestination = destination;
          this.selectedIndex = i;
        } else {
          this.selectedDestination = undefined;
          this.selectedIndex = undefined;
        }
      });
  }

  setVisibility(val: boolean, i: number): void {
    this.state.setHiddenRoute(i, !val);
  }

  setHighlight(i: number): void {
    this.state.setHighlightedRoute(i);
  }

  downloadCsv(downloadButton: any): void {
    this.vehicleTours$
      .pipe(
        take(1)
      )
      .subscribe(vehicleTours => {
        downloadButton.href = `data:text/plain;charset=utf-u,${encodeURIComponent(this.convertVehicleToursToCsv(vehicleTours))}`;
        downloadButton.download = `tour.csv`;
      });
  }

  getTimeWindowsWithPipe(timewindows: Array<Timewindow>): string {
    let timewindowsWithPipe = '';
    timewindows.forEach(timewindow => {
      timewindowsWithPipe += `${timewindow.min}-${timewindow.max}|`;
    });

    if (timewindowsWithPipe.includes('|')) {
      timewindowsWithPipe = timewindowsWithPipe.substr(0, timewindowsWithPipe.length - 1);
    }

    return timewindowsWithPipe;
  }

  // tslint:disable: max-line-length
  convertVehicleToursToCsv(vehicleTours: Array<VehicleTour>): string {
    let csvString = 'name;address;timewindows;servicetime;demand;latitude;longitude';
    if (this.isExtendedMode) {
      csvString += ';vehiclename;vehicletourid;vehicletourname;vehicletourstoparrivaltime;vehicletourstopbeginofservice;vehicletourstopendofservice;vehicletourstopdeparturetime';
    }
    csvString += '\n';
    for (const vehicleTour of vehicleTours) {
      for (const stop of vehicleTour.stops) {
        this.getCustomer(stop.name)
          .subscribe(customer => {
              csvString += `${stop.name};${stop.address};${this.getTimeWindowsWithPipe(customer.timewindows)};${customer.servicetime};${customer.demand};${stop.longitude};${stop.latitude}`;
              if (this.isExtendedMode) {
                csvString += `;${vehicleTour.name};${vehicleTour.type};${vehicleTour.id};${stop.arrivalTime};${stop.beginOfService};${stop.endOfService};${stop.departureTime}`;
              }
              csvString += '\n';
            }
          );
      }
    }

    return csvString;
  }
}
