export class GlobalParams {

  constructor(
    public maxRouteDuration: number = 1440,
    public serviceTime?: number,
    public demand?: number
  ) {
  }

}
