import { Customer } from './customer';
import { GlobalParams } from './global-params';
import { Vehicle } from './vehicle';

export class EditorData {

  constructor(
    public globalParams: GlobalParams,
    public vehicleTypes: Array<Vehicle>,
    public destinations: Array<Customer>
  ) {

  }

}
