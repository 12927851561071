import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { NAVIGATION_ITEMS } from '../../assets/config/navigation.config';
import { StateService } from '../core/state/state.service';
import { NavigationItem } from '../shared/components/navigation/navigation-item';
import { ActionBarState } from '../shared/enums/action-bar-state.enum';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {
  @Input() open: boolean = true;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  public readonly ActionBarState = ActionBarState;

  public readonly selected$: Observable<number> = this.state.sidenavSelectedTab$;

  public readonly navigationItems: Array<NavigationItem> = NAVIGATION_ITEMS;

  public actionBarState: ActionBarState = ActionBarState.Default;

  constructor(private state: StateService) {
  }

  ngOnInit(): void {
  }

  setActionBarState(actionBarState: ActionBarState): void {
    this.actionBarState = actionBarState;
    this.openChange.emit(actionBarState !== ActionBarState.Minimized);
  }

}
