import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'letter'
})
export class LetterPipe implements PipeTransform {

  transform(value: number): any {
    return String.fromCharCode(value + 97);
  }

}
