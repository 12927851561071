import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpBaseService } from './http/http-base.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    HttpBaseService
  ]
})
export class AsyncModule { }
