import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MapService } from 'ngx-mapbox-gl';
import { ToastrModule } from 'ngx-toastr';
import { ActionBarModule } from './action-bar/action-bar.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MapModule } from './map/map.module';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserAnimationsModule,
    ActionBarModule,
    MapModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot()
  ],
  providers: [MapService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
