import { Component, EventEmitter, OnDestroy, OnInit, Output, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { StateService } from '../../../core/state/state.service';
import { GeocodeService } from '../../../map/geocode.service';
import { ActionBarState } from '../../../shared/enums/action-bar-state.enum';
import { Depot } from '../../../shared/models/depot';
import { GeoData } from '../../../shared/models/geo-data';
import { GlobalParams } from '../../../shared/models/global-params';
import { Timewindow } from 'src/app/shared/models/time-window';
import * as moment from 'moment';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: [ './editor.component.scss' ]
})
export class EditorComponent implements OnInit, OnDestroy {

  public destroy$: Subject<any> = new Subject();

  public readonly ActionBarState = ActionBarState;

  public readonly result = this.fb.control('');
  public readonly servicetime = this.fb.control(0, Validators.min(0));
  public readonly demand = this.fb.control(0, Validators.min(0));
  public readonly maxRouteDuration = this.fb.control(1440, Validators.min(1));

  public readonly form: FormGroup = this.fb.group(this.state.depot.timewindows[0]);

  public depotGeoData$: Observable<GeoData> = this.state.depot$.pipe(
    map(depot => new GeoData(depot.longitude, depot.latitude, depot.address))
  );

  @Output() public readonly setActionBarState: EventEmitter<ActionBarState> = new EventEmitter<ActionBarState>();
  @Output() public readonly reset: EventEmitter<never> = new EventEmitter<never>();

  constructor(private fb: FormBuilder, private state: StateService, private geocode: GeocodeService) {
  }

  ngOnInit(): void {

    this.state.resetMapState();

    this.state.globalParams$
      .pipe(
        takeUntil(this.destroy$),
        map(globalParams => globalParams.maxRouteDuration),
        distinctUntilChanged((a, b) => a === b)
      )
      .subscribe(maxRouteDuration => this.maxRouteDuration.patchValue(maxRouteDuration));

    this.state.globalParams$
      .pipe(
        takeUntil(this.destroy$),
        map(globalParams => globalParams.serviceTime),
        distinctUntilChanged((a, b) => a === b)
      )
      .subscribe(serviceTime => this.servicetime.patchValue(serviceTime));

    this.state.globalParams$
      .pipe(
        takeUntil(this.destroy$),
        map(globalParams => globalParams.demand),
        distinctUntilChanged((a, b) => a === b)
      )
      .subscribe(demand => this.demand.patchValue(demand));

    this.maxRouteDuration.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100)
      )
      .subscribe(maxRouteDuration => this.state.globalParams = Object.assign(new GlobalParams(), this.state.globalParams, {maxRouteDuration}));

    this.servicetime.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100)
      )
      .subscribe(serviceTime => this.state.globalParams = Object.assign(new GlobalParams(), this.state.globalParams, {serviceTime}));

    this.demand.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(100)
      )
      .subscribe(demand => this.state.globalParams = Object.assign(new GlobalParams(), this.state.globalParams, {demand}));

    this.state.getEditorData()
      .pipe(
        takeUntil(this.destroy$),
        map(editorData => JSON.stringify(editorData))
      )
      .subscribe(editorData => this.result.patchValue(editorData));

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map(formValues => {
            const newTimewindow: Timewindow = new Timewindow(convertMomentToString(formValues.min),
                                                             convertMomentToString(formValues.max));
            // this.state.globalParams.openingHours = newTimewindow;
            this.state.depot.timewindows = [newTimewindow];
          }
        )
      )
      .subscribe(value => {});

  }

  setGeoData(geoData: GeoData): void {
    this.state.depot = {
      ...this.state.depot,
      ...geoData
    } as Depot;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}

function convertMomentToString(value: any, format: string = 'HH:mm'): string {
  return moment(value, format)
  .format(format);
}
