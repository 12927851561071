import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateService } from '../../core/state/state.service';
import { ActionBarState } from '../../shared/enums/action-bar-state.enum';
import { Customer } from '../../shared/models/customer';
import { Interval } from '../../shared/models/interval';
import { Stop } from '../../shared/models/stop';
import { Timewindow } from '../../shared/models/time-window';
import { VehicleTour } from '../../shared/models/vehicle-tour';

export interface GanttVehicle extends VehicleTour {
  mileStoneLineLength?: { width: string, left: string };
  tourStart?: number;
  tourEnd?: number;
  tourLength?: number;
  stops: Array<GanttStop>;
}

export interface GanttStop extends Stop {
  left: number;
}

export interface GanttDiagram {
  start: number;
  end: number;
  range: number;
  intervals: Array<Interval>;
}

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: [ './job-list.component.scss' ]
})
export class JobListComponent implements OnInit {

  readonly ActionBarState = ActionBarState;

  readonly gantt$: BehaviorSubject<GanttDiagram> = new BehaviorSubject<GanttDiagram>(undefined);

  get gantt(): GanttDiagram {
    return this.gantt$.getValue();
  }

  set gantt(gantt: GanttDiagram) {
    gantt.range = gantt.end - gantt.start;
    gantt.intervals = this.calcIntervals(gantt.start, gantt.range);

    this.gantt$.next(gantt);
  }

  vehicles$: Observable<Array<GanttVehicle>> = this.calcGanttModel();
  customers$: Observable<Array<Customer>> = this.state.customers$;
  selectedRoute$: Observable<number> = this.state.highlightedRoute$

  @Output() public readonly setActionBarState: EventEmitter<ActionBarState> = new EventEmitter<ActionBarState>();

  constructor(private state: StateService) {
  }

  ngOnInit(): void {
  }

  setSelectedRoute(i: number): void {
    this.state.setHighlightedRoute(i);
  }

  private calcGanttModel(): Observable<Array<GanttVehicle>> {
    return zip(this.state.vehicleTours$, this.state.hiddenRoutes$)
      .pipe(
        map(([vehicleTours, hiddenRoutes]) => {
          const filtered = vehicleTours.map((value, index) => hiddenRoutes[index] ? null :value);
          this.gantt = this.calcGanttBounds(filtered.filter(value => !!value));

          return filtered.map(vehicle => this.createGanttVehicle(vehicle));
        })
      );
  }

  private calcGanttBounds(vehicleTours: Array<VehicleTour>): any {
    const getStartTimes = stops => stops.map(stop => this.asMinutes(stop.arrivalTime));
    const getEndTimes = stops => stops.map(stop => this.asMinutes(stop.departureTime))

    const getMin = (v, stops) => Math.min(...[ v, ...getStartTimes(stops) ]);
    const getMax = (v, stops) => Math.max(...[ v, ...getEndTimes(stops) ]);

    return vehicleTours.reduce((acc, curr) => {
      return { start: getMin(acc.start, curr.stops), end: getMax(acc.end, curr.stops) };

    }, { start: 2359, end: 0 });
  }

  private createGanttVehicle(vehicleTour: VehicleTour): GanttVehicle {
    if (!vehicleTour) return null;
    const tourStart: number = Math.min(...vehicleTour.stops.map(stop => this.asMinutes(stop.arrivalTime)));
    const tourEnd: number = Math.max(...vehicleTour.stops.map(stop => this.asMinutes(stop.departureTime)));

    const vehicleTourViewModel: GanttVehicle = {
      ...{
        ...vehicleTour,
        stops: vehicleTour.stops.map(stop => this.createGanttStop(stop)),
        tourStart,
        tourEnd,
        tourLength: tourEnd - tourStart,
        mileStoneLineLength: this.calcMilestoneLineLength(tourStart, tourEnd, this.gantt)
      }

    };

    return vehicleTourViewModel;
  }

  private createGanttStop(stop: Stop): GanttStop {
    return {
      ...stop,
      left: this.calcStopPosition(stop)
    };
  }

  private calcMilestoneLineLength = (tourStart, tourEnd, gantt: GanttDiagram): { width: string, left: string } => {

    return {
      left: `${(tourStart - gantt.start) * 100 / gantt.range}%`,
      width: `${(tourEnd - tourStart) * 100 / gantt.range}%`
    };

  }

  private calcIntervals = (start: number, range: number): Array<Interval> => {
    const arr: Array<Interval> = [];
    const step = this.setStepSize(range);

    let value = Math.ceil(start / step) * step;

    while (value <= start + range) {

      const interval: Interval = {
        value,
        position: (value - start) * 100 / range
      };

      arr.push(interval);

      value += step;
    }

    return arr;
  };

  private setStepSize = (range: number): number =>  {
    if (range > 240) {
      return 60;
    }

    if (range > 120) {
      return 30;
    }

    return 15;
  }

  calcStopConnection(start: Stop, end: Stop): any {
    const e1 = this.calcStopPosition(start);
    const e2 = this.calcStopPosition(end);

    return {
      left: `${e1}%`,
      width: `${e2 - e1}%`
    };

  }

  private calcStopPosition(stop: Stop): number {

    return (this.asMinutes(stop.arrivalTime) - this.gantt.start) * 100 / this.gantt.range;
  }

  calculateTimewindow(timewindow: Timewindow): any {
    const timeWindowMin: number = this.asMinutes(timewindow.min, 'HH:mm');
    const timeWindowMax: number = this.asMinutes(timewindow.max, 'HH:mm');

    if (timeWindowMax >= this.gantt.start && timeWindowMin <= this.gantt.end) {
      return {
        left: `${(Math.max(timeWindowMin, this.gantt.start) - this.gantt.start) * 100 / this.gantt.range}%`,
        width: `${(Math.min(timeWindowMax, this.gantt.end) - Math.max(timeWindowMin, this.gantt.start)) * 100 / this.gantt.range}%`
      };
    }
  }

  calculateWaitingTime(stop: Stop): any {

    return {
      left: `${(this.asMinutes(stop.arrivalTime) - this.gantt.start) * 100 / this.gantt.range}%`,
      width: `${(this.asMinutes(stop.beginOfService) - this.asMinutes(stop.arrivalTime)) * 100 / this.gantt.range}%`
    };
  }

  calculateServiceTime(stop: Stop): any {

    return {
      left: `${(this.asMinutes(stop.beginOfService) - this.gantt.start) * 100 / this.gantt.range}%`,
      width: `${(this.asMinutes(stop.endOfService) - this.asMinutes(stop.beginOfService)) * 100 / this.gantt.range}%`
    };
  }

  private asMinutes = (date: string, format?: string): number => {
    const myMoment = moment(date, format);
    const startOfDay = myMoment.clone()
      .startOf('day');

    return myMoment.diff(startOfDay, 'minutes');
  }
}
