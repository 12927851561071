import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {GeocodeService} from './geocode.service';

@Injectable({
  providedIn: 'root'
})
export class CoordinatePickerService {

  public coordinates$: Subject<any> = new Subject<any>();
  private enableGetCoordinates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public set coordinates(val: any) {
    if (this.enableGetCoordinates) {
      this.coordinates$.next(val);
    }
  }

  public get enableGetCoordinates(): boolean {
    return this.enableGetCoordinates$.getValue();
  }

  public set enableGetCoordinates(val: boolean) {
    this.enableGetCoordinates$.next(val);
  }

  constructor(private geocode: GeocodeService) {
  }

  getCoordinates(): Observable<any> {
    this.enableGetCoordinates = true;

    return this.coordinates$
      .pipe(
        take(1),
        map(coordinates => {
          return {latitude: coordinates.lat, longitude: coordinates.lng};
        })
      );
  }

}
