import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find'
})
export class FindPipe implements PipeTransform {

  transform(items: Array<any>, prop: string, value: any): any {

    return items.find(item => item[ prop ] === value);
  }

}
