import { NgModule } from '@angular/core';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { GeocodeService } from './geocode.service';
import { MapComponent } from './map.component';

@NgModule({
  declarations: [MapComponent],
  imports: [
    SharedModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapBoxAccessToken
    })
  ],
  exports: [
    MapComponent
  ],
  providers: [
    GeocodeService
  ]
})
export class MapModule {
}
