import { Marker } from './marker';
import { Timewindow } from './time-window';

export class Customer implements Marker {
  constructor(
    public name: string = '',
    public longitude: number = undefined,
    public latitude: number = undefined,
    public address: string = '',
    public timewindows: Array<Timewindow> = [new Timewindow()],
    public servicetime: number = 0,
    public demand: number = 0,
    public isEnabled: boolean = true
  ) {

  }
}
