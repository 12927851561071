import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer } from '../../../../shared/models/customer';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

  @Input() public customers: Array<Customer>;

  @Output() public readonly selectedIndex: EventEmitter<number> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleAll(): void {
    const toggleTo: boolean = !this.customers[0].isEnabled;
    this.customers.forEach((customer: Customer) => customer.isEnabled = toggleTo);
  }

}
