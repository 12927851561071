import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { StateService } from '../../../core/state/state.service';
import { EditorData } from '../../models/editor-data';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {

  @Input() file: File;
  @Output() fileChange: EventEmitter<File> = new EventEmitter<File>();
  private reader: FileReader = new FileReader();

  constructor(private toastrService: ToastrService, private state: StateService) {
  }

  ngOnInit(): void {
    this.reader.onload = () => {
      let editorData: EditorData;
      try {
        editorData = JSON.parse(this.reader.result as string);
      } catch (error) {
        this.toastrService.error('JSON is not valid.');
        this.setFile(undefined);

        return;
      }
      this.setEditorData(editorData);
    };
  }

  public dropped(event: UploadEvent): void {
    for (const droppedFile of event.files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          this.processFile(file);
        });
      }
    }
  }

  public processFile(file: File): void {
    this.reader.readAsText(file);
    this.setFile(file);
  }

  public uploadFile(e: any): void {
    this.processFile(e.files[0]);
  }

  public setEditorData(editorData?: EditorData): void {
    this.state.setEditorData(editorData);
  }

  public setFile(file?: File): void {
    this.file = file;
    this.fileChange.emit(file);
  }

  public fileOver(event): void {
  }

  public fileLeave(event): void {
  }

}
