import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export function timeValidator(control: AbstractControl): { [key: string]: boolean } {
  const minValue = control.get('min').value;
  const maxValue = control.get('max').value;
  const minTime = moment.utc(minValue, 'HH:mm');
  const maxTime = moment.utc(maxValue, 'HH:mm');
  return minTime.isBefore(maxTime) ? null : {timeMinMaxInvalid: true};
}
