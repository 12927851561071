import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leftpad'
})
export class LeftpadPipe implements PipeTransform {

  transform(value: number | string, char: string, digits: number = 0): any {
    let temp: number | string = value;

    while ((`${temp}`).length < digits) {
      temp = `${char}${temp}`;
    }

    return temp;
  }

}
