import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AttributionControl, FitBoundsOptions, LngLatBoundsLike, NavigationControl } from 'mapbox-gl';
import * as moment from 'moment';
import { MapComponent as MglMapComponent, MapService } from 'ngx-mapbox-gl';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { RouteState, StateService } from '../core/state/state.service';
import { Customer } from '../shared/models/customer';
import { Depot } from '../shared/models/depot';
import { Marker } from '../shared/models/marker';
import { Stop } from '../shared/models/stop';
import { VehicleTour } from '../shared/models/vehicle-tour';
import { CoordinatePickerService } from './coordinate-picker.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() actionBarOpen: boolean;
  @ViewChild(MglMapComponent) map: MglMapComponent;

  private readonly destroy$: Subject<any> = new Subject();
  private readonly routeTooltipEvents$: Subject<any> = new Subject();
  readonly tooltipContent$: Subject<string> = new Subject();

  @ViewChild('tooltip') tooltip: ElementRef;

  readonly colors = [
    'rgba(49, 183, 188, ',
    'rgba(147, 186, 155, ',
    'rgba(114, 122, 196, ',
    'rgba(166, 134, 211, ',
    'rgba(199, 139, 120, ',
    'rgba(181, 165, 78, ',
    'rgba(180, 138, 160, ',
    'rgba(82, 102, 135, ',
    'rgba(160, 66, 100, ',
    'rgba(94, 130, 113, '
  ];

  readonly sidenavSelectedTab$: Observable<number> = this.state.sidenavSelectedTab$;

  mapBounds: LngLatBoundsLike;
  readonly mapBoundsOptions: FitBoundsOptions = {
    padding: 100
  };

  readonly vehicleTours$: Observable<Array<VehicleTour>> = this.state.vehicleTours$
    .pipe(
      shareReplay(1)
    );

  vehicleRouteData: any;
  markers: Array<Array<Marker> | Marker>;

  readonly hiddenRoutes$: Observable<RouteState> = this.state.hiddenRoutes$;
  readonly highlightedRoute$: Observable<number> = this.state.highlightedRoute$;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private toastrService: ToastrService,
              private state: StateService,
              private coordinatePickerService: CoordinatePickerService,
              private mapService: MapService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.routeTooltipEvents$
      .pipe(
        debounceTime(200),
        takeUntil(this.destroy$),
        tap(val => this.createRouteTooltipData(val))
      )
      .subscribe(val => this.positionTooltip(val));
    this.state.mapBounds$.subscribe(value => this.mapBounds = value);
    this.getRouteData()
      .subscribe(value => {
        this.vehicleRouteData = value;
        this.changeDetectorRef.detectChanges();
      });
    this.sidenavSelectedTab$
      .pipe(
        switchMap(i => i > 0 ? this.getStopsData() : this.state.getDestinations())
      )
      .subscribe(value => this.markers = value);
  }

  ngAfterViewInit(): void {
    this.map.load.subscribe(() => {
      this.map.mapInstance.addControl(new AttributionControl(), 'top-right');
      this.map.mapInstance.addControl(new NavigationControl());
    });
    this.map.error.subscribe(value => {
      if (value) {
        this.toastrService.error(JSON.stringify(value));
      } else {
        // this.toastrService.error('Maybe no network connection?', 'INTERNAL ERROR');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.actionBarOpen) {
      this.resetBounds();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resetBounds();
  }

  resetBounds(): void {
    if (!this.map || !this.map.mapInstance) { return; }
    setTimeout(() => { this.map.mapInstance.resize(); }, 50); // Workaraound to scale map after hiding left bar.
    this.map.mapInstance.resize();
    this.map.mapInstance.fitBounds(this.mapBounds, this.mapBoundsOptions);
  }

  getRouteData(): Observable<any> {
    const sanitize = geometryData => geometryData
      .replace(/[^\d., ]/g, '')
      .trim();

    const mapToArray = routeString => routeString
      .split(',')
      .map(c => c.split(' '));

    const convertToLine = coordinates => ({type: 'LineString', coordinates});

    return this.vehicleTours$
      .pipe(
        map(vehicleTours => vehicleTours.map(vehicleTour => {
            return {
              id: vehicleTour.id,
              name: vehicleTour.name,
              type: vehicleTour.type,
              stops: vehicleTour.stops,
              routes: vehicleTour.routes.map(route => {
                return {
                  ...route,
                  geoData: convertToLine(mapToArray(sanitize(route.wkt)))
                };
              })
            };
          })
        ),
      );
  }

  getStopsData(): Observable<any> {
    const getTimeDiffInMin = (start, end) => Math.round((new Date(end).getTime() - new Date(start).getTime()) / 60000);

    const convertToMarker = (stop: Stop, customer: Customer): Marker => {
      return {
        coordinates: [stop.longitude, stop.latitude],
        name: stop.name,
        timewindows: customer ? customer.timewindows : undefined,
        servicetime: customer ? customer.servicetime : undefined,
        waitingtime: customer ? getTimeDiffInMin(stop.arrivalTime, stop.beginOfService) : undefined,
        demand: customer ? customer.demand : undefined,
        isEnabled: customer ? customer.isEnabled : true
      };
    };

    return this.vehicleTours$
      .pipe(
        withLatestFrom(this.state.customers$),
        map(([vehicleTours, customers]) => vehicleTours
          .map(vehicleTour => vehicleTour.stops
            .map(stop => convertToMarker(stop, customers.find(customer => customer.name === stop.name))))
        )
      );
  }

  setCoordinates(event: any): void {
    if (event.lngLat) {
      this.coordinatePickerService.coordinates = event.lngLat;
    }
  }

  trackByFn(index: number, data: any): void {
    return data;
  }

  isFaded(i: number): Observable<string> {
    return this.highlightedRoute$
      .pipe(
        map(highlightedRoute => `${!isNaN(highlightedRoute) && i !== highlightedRoute ? 0.5 : 1})`)
      );
  }

  addTooltipEvent(payload: any): void {
    this.routeTooltipEvents$.next(payload);
  }

  positionTooltip(payload: any): void {
    const el: HTMLElement = this.tooltip.nativeElement;

    el.dataset.vehicle = payload.vehicle.id;
    el.style.top = `${payload.event.point.y - 5}px`;
    el.style.left = `${payload.event.point.x - 5}px`;
  }

  createRouteTooltipData(payload: any): void {
    const index = payload.index;
    const route = payload.route;
    const stops = payload.vehicle.stops;

    const toUppercaseLetter = val => String
      .fromCharCode(val + 97)
      .toUpperCase();

    const from: string = index === 0 ? 'Depot' : toUppercaseLetter(index - 1);
    const to: string = index >= payload.vehicle.routes.length - 1 ? 'Depot' : toUppercaseLetter(index);

    const convertDateToHHmm = str => moment(str)
      .format('LT');

    const secondsToHH = value => `${Math.floor(value / 3600)}`;
    const secondsTomm = value => `0${Math.floor(value % 3600 / 60)}`.substr(-2);

    const fromTo: string = `${stops[index].name}${from!=='Depot' ? ` (${from})` : ''} to ${stops[index + 1].name}${to!=='Depot' ? ` (${to})` : ''}`;
    const timeHHmm: string = `${this.translate.instant('DEPARTURE')}: ${convertDateToHHmm(route.startTime)}, ${this.translate.instant('ARRIVAL')}:  ${convertDateToHHmm(route.endTime)}`;
    const durationHHmm: string = `${this.translate.instant('DURATION')}: ${secondsToHH(route.traveltimeSeconds)}:${secondsTomm(route.traveltimeSeconds)}h`;
    const distanceKm: string = `${this.translate.instant('DISTANCE')}: ${(route.distanceMeters / 1000).toFixed(1)}km`;

    const tooltipContent: string = `${fromTo}:\n${timeHHmm}\n${durationHHmm}, ${distanceKm}`;

    this.tooltipContent$.next(tooltipContent);
  }

  getMarkerTooltipData(marker: Marker): string {
    const name: string = marker.name;
    const customer = this.state.customers.find(c => c.name === marker.name);
    if (!customer) {
      console.warn('no customer for marker', marker);
    }
    const address: string = customer ? customer.address : 'No address - because no customer found!';
    const timewindows: string = `${this.translate.instant('TIME_WINDOW')}:\n${marker.timewindows
      .map(timewindow => `${timewindow.min} - ${timewindow.max}`)
      .join(', ')}`;
    const serviceTime = `${this.translate.instant('SERVICE_TIME')}: ${marker.servicetime || 0}min`;
    const demandTime = `${this.translate.instant('DEMAND')}: ${marker.demand || 0}`;
    const waitingTime = `${this.translate.instant('WAITING_TIME')}: ${marker.waitingtime || 0}min`;
    const timeOnSite = `${serviceTime}, ${demandTime}${marker.waitingtime ? `, ${waitingTime}` : ''}`;

    return `${name}, ${address}\n${timewindows}\n${timeOnSite}`;
  }

  setHighlightedRoute(i: number): void {
    this.state.highlightedRoute = i;
  }

  getVehicleIndex(): void {
    this.state.highlightedRoute = +this.tooltip.nativeElement.dataset.vehicle;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
