import { NavigationItem } from '../../app/shared/components/navigation/navigation-item';

export const NAVIGATION_ITEMS = [
  new NavigationItem(
    'editor',
    'GLOBAL_DATA',
    'csv@2x',
    'highlight-0'
  ),
  new NavigationItem(
    'summary',
    'SUMMARY',
    'summary@3x',
    'highlight-10'
  ),
  new NavigationItem(
    'jobList',
    'JOB_LIST',
    'Joblist@3x',
    'highlight-10'
  ),
];
